import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeRequest } from 'services/APIService';
import { REMOTE } from 'constants/serverRoutes';
import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import DateRangePicker from 'components/organisms/DateRangePicker';
import { format, parseISO, subDays } from 'date-fns';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/system';
import Container from 'components/atoms/Container';
import Item from 'components/atoms/Item';
import { FilterAlt, Search } from '@mui/icons-material';

/**
 *  filters: Array[Object]
 *      id: String              API queryKey
 *      type: String            <dropdown/multiDropDown/typeAhead/number/dateRange/exportExcel>
 *      label: String
 *      default: String         default value for reset
 *      resource: String        only for typeAhead
 *      labelFields: String     only for typeAhead
 *      option: Array[Object]   only for dropdown/multiDropDown
 *          text: String        displayText
 *          value: String       filterValue
 *  onSubmit: Function
 *  onExport: Function
 *  isButtonDisabled: Boolean
 *
 * */
const Filter = ({ filters, isButtonDisabled, onSubmit: onSearch, filterDateRange }) => {
  const DEFAULT_DAYS = filterDateRange || 3;
  const [filterState, setFilterState] = useState({
    isLoading: false,
    isNikValue: false,
    isUserInfoValue: false,
    usersInfo: [],
    ...filters
      .map((field) => field.id)
      .reduce((state, field) => {
        state[field] = '';
        return state;
      }, {}),
    ...filters
      .filter((f) => f.type === 'typeAhead')
      .map((field) => field.id)
      .reduce((state, field) => {
        state[field + 'TypeOptions'] = [];
        return state;
      }, {}),
    ...filters
      .filter((f) => f.type === 'multiDropDown')
      .map((field) => field)
      .reduce((state, field) => {
        state[field.id + 'Value'] = field.default || [];
        return state;
      }, {}),
      startDate: format(subDays(new Date(), DEFAULT_DAYS), 'yyyy-MM-dd'),
      endDate: format(new Date(), 'yyyy-MM-dd')
  });
  const autoC = useRef(null);
  const { search } = useLocation();
  useEffect(() => {
    onRouteChanged();
  }, [search]);

  const onRouteChanged = () => {
    const queryState = getLocationSearch();
    setFilterState((prevState) => ({
      ...prevState,
      ...queryState
    }));
    onSubmit(queryState.activePage);
  };
  const handleDateChange = (range) => {
    const startDate = format(range.startDate, 'yyyy-MM-dd');
    const endDate = format(range.endDate, 'yyyy-MM-dd');
    setFilterState((prevState) => ({ ...prevState, startDate, endDate }));
  };

  const getLocationSearch = () => {
    var pairs = search.slice(1).split('&');
    var result = {};
    pairs.forEach((pair) => {
      pair = pair.split('=');
      result[pair[0]] = decodeURIComponent(pair[1] || '');
      const filterField = filters.find((f) => f.id === pair[0]);

      if (filterField && filterField.type === 'multiDropDown') {
        result[filterField.id + 'Value'] = filterField.option.filter(
          (ops) => result[filterField.id].split(',').indexOf(ops.value) > -1
        );
      }
    });
    return result;
  };

  const handleTypeChange = (elementType, newValue) => {
    setFilterState((prevState) => ({
      ...prevState,
      [elementType]: (newValue && newValue.id) || ''
    }));
  };
  const handlerInputChange = (elementType, event) => {
    setFilterState((prevState) => ({
      ...prevState,
      [elementType]: event.target.value
    }));
  };

  const selectMultipleOption = (elementType, e) => {
    const {
      target: { value }
    } = e;
    setFilterState((prevState) => ({
      ...prevState,
      [elementType + 'Value']: value
    }));
  };

  const getTypeAheadData = (field, e) => {
    const {
      target: { value: q }
    } = e;
    if (q) {
      return makeRequest({
        url: `${REMOTE[field.resource]}/filter`,
        params: { page: 1, limit: 10, q: q.substr(0, 100) }
      })
        .then((result) => {
          if (result.data && result.data.data) {
            return result.data.data.map((d) => {
              return {
                id: d.id,
                label: field.labelFields.map((f) => d[f]).join(' || ')
              };
            });
          }
          return [];
        })
        .then((json) => {
          setFilterState((prevState) => ({
            ...prevState,
            isLoading: false,
            [field.id + 'TypeOptions']: json
          }));
        });
    }
  };

  const dateRangeFilter = ({ id }) => (
    <Grid key={id} item>
      <DateRangePicker
        initialDateRange={{
          startDate: parseISO(filterState.startDate),
          endDate: parseISO(filterState.endDate)
        }}
        defaultAllowedDays={DEFAULT_DAYS}
        onChange={handleDateChange}
      />
    </Grid>
  );

  const getFilterQuery = (page) => {
    let query = {
      activePage: page
    };
    filters.forEach((field) => {
      if (!field.id) {
        return;
      }
      if (field.type === 'dateRange') {
        query['startDate'] = filterState.startDate;
        query['endDate'] = filterState.endDate;
        return;
      }
      if (field.type === 'multiDropDown') {
        query[field.id] = filterState[field.id + 'Value']
          ? filterState[field.id + 'Value'].map((val) => val).join(',')
          : null;
        return;
      }

      if (field.func) {
        field.func(query, filterState[field.id]);
      } else {
        query[field.id] =
          filterState[field.id] &&
          filterState[field.id] !== '' &&
          filterState[field.id] !== field.default
            ? filterState[field.id]
            : field.defaultValue || null;
      }
    });

    return query;
  };

  const getResetFilter = () => {
    let setQuery = {};
    let query = {};
    filters.forEach((field) => {
      if (!(field.id === 'date' || field.id === undefined)) {
        if (field.func) {
          field.func(query, '', setQuery);
        } else {
          query[field.id] = '';
          setQuery[field.id] = field.default || '';
        }
      }
      if (field.type === 'typeAhead') {
        query[field.id] = '';
        setQuery[field.id] = field.default || '';
        const ele = autoC.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
        if (ele) ele.click();
        setFilterState((prevState) => ({
          ...prevState,
          [field.id]: '',
          [field.id + 'TypeObject']: []
        }));
      }
      if (field.type === 'multiDropDown') {
        setQuery = {
          ...setQuery,
          [field.id]: field.default ? field.default.map((val) => val.value).join(',') : null
        };

        setFilterState((prevState) => ({
          ...prevState,
          [field.id + 'Value']: field.default
        }));
      }
      if (field.type === 'dateRange') {
        query['startDate'] = format(subDays(new Date(), DEFAULT_DAYS), 'yyyy-MM-dd');
        query['endDate'] = format(new Date(), 'yyyy-MM-dd');

        setQuery['startDate'] = format(subDays(new Date(), DEFAULT_DAYS), 'yyyy-MM-dd');
        setQuery['endDate'] = format(new Date(), 'yyyy-MM-dd');
      }
      if (field.defaultValue) {
        query[field.id] = field.defaultValue || '';
        setQuery[field.id] = field.defaultValue || '';
      }
    });
    setFilterState(setQuery);
    return query;
  };

  const onSubmit = (page, e) => {
    if (e) {
      e.preventDefault();
    }
    page = page || 1;
    let query = getFilterQuery(parseInt(page, 10));
    onSearch(query);
  };

  const showAll = () => {
    let query = getResetFilter();
    onSearch(query);
  };

  const filterComponent = (filters) => {
    let { isNikValue, isUserInfoValue } = filterState;
    if (filters && filters.length > 0) {
      let filComponents = filters.map((field) => {
        let fieldComponent;
        if (field.type === 'text') {
          if (field.label === 'NIK' && this.state[field.id].length > 0) {
            isNikValue = true;
          }
          fieldComponent = (
            <Grid
              sx={{
                marginRight: '20px',
                marginBottom: '15px'
              }}
              item
              xs={filters.length > 2 ? 2 : 3}
              key={field.id}>
              <Tooltip title={field.label}>
                <FormControl fullWidth size="small">
                  <TextField
                    size="small"
                    label={field.label}
                    variant="outlined"
                    value={filterState[field.id]}
                    onChange={(e) => handlerInputChange(field.id, e)}
                  />
                </FormControl>
              </Tooltip>
            </Grid>
          );
        } else if (field.type === 'typeAhead') {
          fieldComponent = (
            <Grid
              sx={{
                marginRight: '20px',
                marginBottom: '15px'
              }}
              item
              xs={filters.length > 2 ? 2 : 3}
              key={field.id}>
              <Tooltip title={field.label}>
                <Autocomplete
                  ref={autoC}
                  disablePortal
                  size="small"
                  fullWidth
                  onInputChange={(e) => getTypeAheadData(field, e)}
                  onChange={(e, newValue) => handleTypeChange(field.id, newValue)}
                  options={filterState[field.id + 'TypeOptions'] || []}
                  renderInput={(params) => <TextField {...params} label={field.label} />}
                />
              </Tooltip>
            </Grid>
          );
        } else if (field.type === 'dropdown') {
          const options = field.option ? field.option : field.options;
          fieldComponent = (
            <Grid
              sx={{
                marginRight: '20px',
                marginBottom: '15px'
              }}
              item
              xs={filters.length > 2 ? 2 : 3}
              key={field.id}>
              <Tooltip title={field.label}>
                <FormControl fullWidth size="small">
                  <InputLabel id={field.id}>{field.label}</InputLabel>
                  <Select
                    labelId={field.id}
                    id={field.id}
                    value={filterState[field.id]}
                    label={field.label}
                    onChange={(e) => handlerInputChange(field.id, e)}>
                    {options.map((option) => {
                      return (
                        <MenuItem key={option.value} value={option.value}>
                          {option.text}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Tooltip>
            </Grid>
          );
        } else if (field.type === 'multiDropDown') {
          fieldComponent = (
            <Grid
              sx={{
                marginRight: '20px',
                marginBottom: '15px'
              }}
              item
              xs={filters.length > 2 ? 2 : 3}
              key={field.id}>
              <Tooltip title={field.label}>
                <FormControl fullWidth size="small">
                  <InputLabel id={field.id}>{field.label}</InputLabel>
                  <Select
                    labelId={field.id}
                    multiple
                    value={filterState[field.id + 'Value'] || []}
                    onChange={(e) => selectMultipleOption(field.id, e)}
                    input={<OutlinedInput label={field.label} />}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((val) => (
                          <Chip key={val} label={val} />
                        ))}
                      </Box>
                    )}>
                    {field.option.map((val) => {
                      return (
                        <MenuItem key={val.name} value={val.value}>
                          <Checkbox
                            checked={filterState[field.id + 'Value']?.includes(val.value)}
                          />
                          <ListItemText primary={val.name} />
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Tooltip>
            </Grid>
          );
        } else if (field.type === 'number') {
          fieldComponent = (
            <Grid
              sx={{
                marginRight: '20px',
                marginBottom: '15px'
              }}
              item
              xs={filters.length > 2 ? 2 : 3}
              key={field.id}>
              <Tooltip title={field.label}>
                <FormControl fullWidth size="small">
                  <TextField
                    size="small"
                    label={field.label}
                    variant="outlined"
                    type="number"
                    value={filterState[field.id]}
                    InputProps={{
                      inputProps: {
                        min: 0
                      }
                    }}
                    onChange={(e) => handlerInputChange(field.id, e)}
                  />
                </FormControl>
              </Tooltip>
            </Grid>
          );
        } else if (field.type === 'dateRange') {
          fieldComponent = (
          <Grid key={field.id}  sx={{
            marginRight: '20px',
            marginBottom: '15px'
          }}
          item>
            <DateRangePicker
              initialDateRange={{
                startDate: parseISO(filterState.startDate),
                endDate: parseISO(filterState.endDate)
              }}
              defaultAllowedDays={DEFAULT_DAYS}
              onChange={handleDateChange}
            />
          </Grid>
          );
        }
        return fieldComponent;
      });
      return (
        <Container
          sx={{
            marginBottom: '30px',
            alignItems: 'center'
          }}>
          {filComponents}
          <Item
            lg={3}
            sx={{
              marginBottom: '15px'
            }}>
            <Button
              type={'submit'}
              sx={{
                mr: '5px'
              }}
              size="small"
              disabled={isButtonDisabled}
              variant="contained">
              <Search />
              Search
            </Button>
            <Button variant="contained" size="small" onClick={showAll}>
              <FilterAlt /> Filters
            </Button>
          </Item>
        </Container>
      );
    }
    return <tr />;
  };

  const filteredComponent = filterComponent(filters);
  if (filters.length === 0) {
    return <tr />;
  }
  return (
    <Fragment>
      <Typography
        variant="h6"
        sx={{
          marginBottom: '20px'
        }}>
        Filter
      </Typography>
      <form onSubmit={(e) => onSubmit(1, e)}>{filteredComponent}</form>
    </Fragment>
  );
};

Filter.propTypes = {
  isLoading: PropTypes.bool,
  filters: PropTypes.array,
  onSubmit: PropTypes.func,
  onExport: PropTypes.func,
  onEmailExport: PropTypes.func,
  onRegisteredInKsei: PropTypes.func,
  onRegisterToKsei: PropTypes.func,
  isButtonDisabled: PropTypes.bool,
  filterDateRange: PropTypes.number,
};

export default Filter;
