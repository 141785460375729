export const filter = [
  {
    id: 'subCategory',
    type: 'dropdown',
    label: 'Currency Name',
    default: 'all',
    option: [{ text: 'All', value: 'all' }]
  },
  {
    id: 'status',
    type: 'dropdown',
    label: 'Status',
    default: 'SUCCESS',
    option: [
      { text: 'All', value: 'all' },
      { text: 'Pending', value: 'PENDING' },
      { text: 'Success', value: 'SUCCESS' },
      { text: 'Failed', value: 'FAILED' }
    ]
  },
  {
    id: 'transactionType',
    type: 'dropdown',
    label: 'Transaction Type',
    default: 'all',
    option: [
      { text: 'All', value: 'all' },
      { text: 'Buy', value: 'buy' },
      { text: 'Sell', value: 'sell' }
    ]
  },
  {
    id: 'date',
    type: 'dateRange',
    label: 'DateFilter'
  }
];

export const headers = [
  { header_name: 'Trans No', db_column_name: 'id' },
  { header_name: 'Trans Type', db_column_name: 'transactionType' },
  { header_name: 'Quantity', db_column_name: 'quantity' },
  { header_name: 'Unit Price', db_column_name: 'unitPrice', formattedCurrency: true },
  { header_name: 'Transaction Fee', db_column_name: 'transactionFee', formattedCurrency: true },
  { header_name: 'Taxation Fee', db_column_name: 'taxationFee', formattedCurrency: true },
  { header_name: 'Total Price', db_column_name: 'totalPrice', formattedCurrency: true },
  { header_name: 'Status', db_column_name: 'status' },
  { header_name: 'Order Type', db_column_name: 'orderType' },
  { header_name: 'Currency Name', db_column_name: 'cryptoCurrencyName' },
  { header_name: 'Created', db_column_name: 'created' }
];
